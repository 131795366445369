

body {
  @apply bg-bg-900;
}

// Ad styling
#stickyfooter {
  background-color: #0000008c !important;
}

.fade-custom-enter-active,
.fade-custom-leave-active {
  transition: opacity 0.25s;
}

.fade-custom-enter,
.fade-custom-leave-to {
  opacity: 0;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 0.9em;
}

a {
  color: white;
  text-decoration: none;
}

h2 {
  font-family: BeaufortforLOL-Bold, sans-serif;
  font-size: 1.25em;
}

.dropdown-icon {
  vertical-align: middle;
  width: 17px;

  &.reverse {
    transform: rotate(180deg);
  }

  &.hide {
    visibility: hidden;
  }
}

.body-container {
  // height: 100vh;
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Tooltip Stylings */
.tooltip-img {
  margin-right: 5px;
  width: 25px;
  object-fit: cover;
}

.item-name {
  font-size: 1.1em;
}

.q-poor {
  color: #9d9d9d;
}

.q-common {
  color: white;
}

.q-uncommon {
  color: #1eff00;
}

.q-rare {
  color: #0070dd;
}

.q-epic {
  color: #a335ee;
}

.q-legendary {
  color: #ff8000;
}

.q-artifact {
  color: #e6cc80;
}

.misc {
  color: #ffd100;
}

.white-wrap {
  white-space: break-spaces;
}
