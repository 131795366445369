@import 'colors';

@mixin drop-shadow-0 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.skeleton-loader {
  position: relative;
  overflow: hidden;

  @apply bg-bg-900 bg-opacity-95;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image:
      linear-gradient(
        90deg,
        rgba($color-bg-highlight, 0) 0,
        rgba($color-bg-highlight, 0.3) 50%,
        rgba($color-bg-highlight, 0) 100%
      );
    animation: shimmer 1.5s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
