@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants hover {
    .filter-brightness {
      filter: brightness(1.35);
    }
  }
}

@layer base {
  body {
    scroll-behavior: smooth;

    .sidebar {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .sidebar > * {
      flex: 0 0 auto;
      margin-bottom: 25px;
    }

    .sidebar > *:last-child {
      margin-bottom: 0;
    }

    .sidebar #sample-sticky-stack {
      flex: 1;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    &::-webkit-scrollbar-track-piece {
      background: #1d1d1d;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-bg-500 rounded-3xl;

      width: 50px;
    }
  }

  .social-button {
    @apply inline-flex justify-center w-full px-4 py-2 text-sm font-medium transition-colors border rounded-md shadow-sm text-bg-200 border-bg-200 hover:bg-bg-dark hover:text-primary;
  }

  /* clears the 'X' from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  .default-input {
    @apply w-full h-10 py-0 px-2.5 font-sans text-sm text-gray-200 transition border-transparent cursor-text rounded focus:outline-none bg-bg-900 border-2 border-gray-500 focus:ring-primary focus:border-primary;
  }

  .default-checkbox {
    @apply transition-colors bg-transparent border-gray-300 rounded text-primary focus:ring-0 focus:ring-offset-transparent focus:ring-primary focus:border-transparent hover:ring-primary;
  }

  /* This code needs some re-work - it's overwriting pretty much all of the input and button styles
  throughout the application and doesn't allow anything to overwrite it -
  we need to make this the default styling, but allow for manipulation so I've added a default-input class above ^ */

  /* input[type='checkbox'] {
    @apply transition-colors bg-transparent border-gray-300 rounded text-primary focus:ring-0 focus:ring-offset-transparent focus:ring-primary focus:border-transparent hover:ring-primary;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    @apply relative p-2 mt-1 text-sm text-white placeholder-gray-300 transition-colors border-transparent rounded outline-none focus:outline-none bg-bg-dark focus:ring-1 focus:ring-primary focus:border-primary hover:ring-primary;
  } */

  /* button {
    @apply relative w-full p-2 mt-1 text-sm placeholder-gray-300 transition-colors border-transparent rounded outline-none focus:outline-none bg-bg-dark focus:ring-1 focus:ring-primary focus:border-primary hover:ring-primary focus:ring-offset-black;
  } */
}
