
.bg-picture {
  background-image: linear-gradient(0deg, rgba(20,30,35,1) 10%, rgba(0,212,255,0) 40%),url('../assets/img/backgrounds/icecrown.png');
  background-repeat: no-repeat;
}
#background {
  grid-template-columns: 1fr fit-content(56rem) 1fr;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
  }
}
#main {
  grid-column: 2 / 3;
}
