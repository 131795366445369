<template>
  <div
    id="tooltip"
    class="p-2 text-left min-w-max"
  >
    <img
      v-if="achievement.icon"
      class="absolute -top-0.5 inline w-12 h-auto border-2 border-tooltip-border -left-16"
      :src="`https://cdn.wowclassicdb.com/icons/${achievement.icon.name}.jpg`"
      :alt="achievement.name"
    >
    <div class="flex flex-col items-center space-y-4">
      <div>
        <div>
          <div class="flex space-y-2 flex-col">
            <h1 class="font-bold text-lg text-tooltip-misc">
              {{ achievement.name }}
            </h1>
            <span class="flex max-w-xs flex-wrap">
              {{ achievement.description }}
            </span>
            <div v-if="achievement.points">
              <span class="text-tooltip-misc">Points:</span>
              <span class="flex max-w-xs flex-wrap">
                {{ achievement.points }}
              </span>
            </div>
            <div v-if="achievement.achievementCategory">
              <span class="text-tooltip-misc">Category:</span>
              <span class="flex max-w-xs flex-wrap">
                {{ achievement.achievementCategory.name }}
              </span>
            </div>
            <div
              v-if="achievementCriteriaExists"
              class="text-tooltip-misc"
            >
              Criteria:
            </div>
            <div
              v-if="achievementCriteriaExists"
              class="flex flex-wrap my-0"
            >
              <div class="flex flex-col flex-wrap mr-5">
                <div
                  v-for="(criteria, i) in criteriaFirstGroup"
                  :key="i"
                  class="flex max-w-xs flex-wrap q-poor"
                >
                  <div>
                    {{ criteria.name }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col flex-wrap">
                <div
                  v-for="(criteria, i) in criteriaSecondGroup"
                  :key="i"
                  class="flex max-w-xs flex-wrap q-poor"
                >
                  <div>
                    {{ criteria.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Achievement } from '@/models/Achievement'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'AchievementTooltip',
  props: {
    achievement: {
      type: Object as PropType<Achievement>,
      required: true
    }
  },
  computed: {
    criteriaFirstGroup () {
      if (!this.achievement) return null;
      const breakPoint = this.achievement.achievementCriteria.length % 2 === 0 ? this.achievement.achievementCriteria.length / 2 : this.achievement.achievementCriteria.length / 2 + 1
      return this.achievement.achievementCriteria.slice(0, breakPoint)
    },
    criteriaSecondGroup () {
      if (!this.achievement) return null;
      const breakPoint = this.achievement.achievementCriteria.length % 2 === 0 ? this.achievement.achievementCriteria.length / 2 : this.achievement.achievementCriteria.length / 2 + 1
      return this.achievement.achievementCriteria.slice(breakPoint)
    },
    achievementCriteriaExists () {
      const criterias = this.achievement.achievementCriteria
      return criterias && criterias.length > 0 && criterias[0].name.length > 0
    }
  }
})
</script>

<style lang="postcss" scoped>

#tooltip {
  top: 5000px;
  left: 5000px;
}

.my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
</style>
